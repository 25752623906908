import { DocumentIcon, PlusIcon } from 'stories/Icons';

import type { GlyphName } from '../types';

type GlyphProps = {
  glyphName: GlyphName;
  color: string;
  size: string;
};

const Glyph = ({ glyphName, color, size }: GlyphProps) => {
  switch (glyphName) {
    case 'plus': {
      return <PlusIcon fillColor={color} width={size} height={size} />;
    }
    case 'pdf': {
      return (
        <DocumentIcon
          fillColor={color}
          width={size}
          height={size}
          variant="pdf"
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default Glyph;
