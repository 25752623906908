import styled, { css, DefaultTheme } from 'styled-components';
import { spacingUtils } from 'utils/helpers/spacingUtils';

import { KindType, StateType, StyledButtonProps, VariantType } from '../types';

/**
 * Get the color based on the button kind and state.
 */
const getKindColor = (
  kind: KindType,
  state: StateType,
  theme: DefaultTheme
) => {
  const { primary, green: success, red: destructive, grey } = theme.colors;

  if (state === 'inactive') {
    return [grey[300], grey[300], grey[300]] as const;
  }

  switch (kind) {
    case 'neutral':
      return [primary[500], primary[450], primary[600]] as const;
    case 'destructive':
      return [destructive[500], destructive[450], destructive[600]] as const;
    case 'success':
      return [success[500], success[450], success[600]] as const;
    default:
      throw new Error(`Invalid kind ${kind}`);
  }
};

/**
 * Get the styles based on the button variant.
 */
const getVariantStyles = (
  variant: VariantType,
  kind: KindType,
  state: StateType,
  theme: DefaultTheme
) => {
  const [normal, light, dark] = getKindColor(kind, state, theme);
  const colors = theme.colors;

  switch (variant) {
    case 'primary':
      return css`
        color: ${colors.grey[0]};
        fill: ${colors.grey[0]};
        background-color: ${normal};
        &:hover:not(:disabled) {
          background-color: ${light};
        }
        &:active:not(:disabled) {
          background-color: ${dark};
        }
      `;
    case 'secondary':
      return css`
        color: ${normal};
        fill: ${normal};
        background-color: transparent;
        border: 1px solid ${normal};
        &:hover:not(:disabled) {
          border: 1px solid ${light};
        }
        &:active:not(:disabled) {
          border: 1px solid ${dark};
        }
      `;
    case 'tertiary':
    case 'glyph':
      return css`
        color: ${normal};
        fill: ${normal};
        background-color: transparent;
        &:hover:not(:disabled) {
          color: ${light};
          fill: ${light};
          background-color: rgba(0, 0, 0, 0.1);
        }
        &:active:not(:disabled) {
          color: ${dark};
          fill: ${dark};
          background-color: rgba(0, 0, 0, 0.2);
        }
      `;
    default:
      throw new Error(`Invalid variant ${variant}`);
  }
};

/**
 * Get the padding based on the button variant and glyph presence.
 */
const getPadding = (
  variant: VariantType = 'primary',
  hasGlyph: boolean = false,
  theme: DefaultTheme
) => {
  if (variant === 'glyph') {
    return theme.spacing[4];
  }

  return hasGlyph
    ? `${theme.spacing[4]} ${theme.spacing[4]} ${theme.spacing[4]} ${theme.spacing[3]}`
    : `${theme.spacing[4]} ${theme.spacing[6]}`;
};

/**
 * Get the height based on the button size.
 */
const getHeights = (size: 'large' | 'standard' | 'small' = 'standard') => {
  const heightMap = {
    standard: '40px',
    large: '60px',
    small: '32px',
  };

  return heightMap[size];
};

export const StyledBaseButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: ${({ theme }) => theme.styles.borderRadius};
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};

  cursor: pointer;

  padding: ${({ variant, hasGlyph, theme }) =>
    getPadding(variant, hasGlyph, theme)};
  height: ${({ size = 'standard' }) => getHeights(size)};

  ${({ variant = 'primary', state = 'default', kind = 'neutral', theme }) =>
    getVariantStyles(variant, kind, state, theme)}

  &:disabled {
    cursor: default;
  }

  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  ${spacingUtils}
`;
